
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends style of component */

:root {
    --newsletter-subscription-button-background: var(--link-color);
}

.ContentWrapper {
    &.Newsletter-NewsletterContent {
        padding: 17px 50px 26px;
        background-color: #272727;
        color: #fff;
        margin-block: 120px 160px;
        max-width: calc( var(--content-wrapper-width) - 64px);

        @include narrow-desktop {
            margin-block: 80px 80px;
            padding: 30px 50px 30px;
        }

        @include tablet {
            margin-block: 80px 70px;
            padding: 17px 30px 26px;
        }

        @include mobile {
            padding: 20px 25px 28px;
            margin-block: 80px 50px;
        }
        
        p {
            color: #fff;
        }

        .Newsletter-CalltoAction {
            @include tablet {
                width: 40%;
                display: inline-block;
                vertical-align: middle;
            }

            @include mobile {
                text-align: center;
            }

            p {
                font-size: 8px;
            }
            
            h4 {
                font-size: 33px;
                line-height: 1.2;
                color: #fff;

                @include narrow-desktop {
                    font-size: 2.8rem;
                }

                @include tablet {
                    font-size: 2.4rem;
                }

                @include mobile {
                    font-size: 2.4rem;
                    text-align: center;
                }

                &:first-child::before {
                    content: var(--newsletter-icon);
                    width: 92px;
                    height: 92px;
                    border-radius: 50%;
                    background-color: $default-primary-light-color;
                    display: block;
                    margin-block-end: 2px;
                    padding-block-start: 30px;
                    box-sizing: border-box;
                    text-align: center;

                    @include mobile {
                        margin: 0 auto;
                        margin-block-start: -79px;
                        margin-block-end: 20px;
                    }
                }
            }
        }

        .Newsletter-Subscription {
            padding-block-start: 20px;

            @include narrow-desktop {
                padding-block-start: 0;
            }

            @include tablet {
                width: 60%;
                display: inline-block;
                vertical-align: middle;
            }

            h4 {
                color: #fff;
                font-weight: 400;
                margin-block-end: 8px;

                @include mobile {
                    text-align: center;
                }
            }

            .FieldForm {
                &-Fieldset {
                    .FieldGroup_isValid {
                        padding-inline-start: 0;
                        border-left: none;
                    }

                    .Field {
                        width: 100%;
                        margin-block-start: 0;
        
                        input {
                            &[type=email] {
                                background-color: transparent;
                                padding: 30px 19px 13px;
                                font-size: 12px;
                                color: #fff;
                                border: 1px solid #DBFFC6;
                                font-weight: 400;
                                
                                @include tablet {
                                    height: 50px;
                                }

                                &:focus {
                                    border: 1px solid #fff;
                                }

                                &::placeholder {
                                    color: #fff;
                                    opacity: 1;
                                    font-weight: 400;
                                }
                            }

                            &[type=checkbox] {
                                padding: 0;
                                border: 1px solid #fff;
                                border-radius: 50%;

                                + .input-control {
                                    border-radius: 12px;
                                    max-width: 53px;
                                    width: 12%;

                                    &::after {
                                        box-shadow: none;
                                        content: '';
                                        height: 8px;
                                        width: 8px;
                                        inset-block-start: 4px;
                                        inset-inline-start: 4px;
                                        transform: none;
                                        border-radius: 10px;
                                        transition: none;
                                    }
                                }

                                &:hover:not(:disabled) + .input-control {
                                    --box-color: #fff;

                                    border: 1px solid $default-primary-dark-color;
                                    margin-inline-end: 11px;
                                }
                            }
                        }

                        &.Field_type_checkbox {
                            margin-block-start: 20px;
                            font-size: 10px;

                            .Field-CheckboxLabel,
                            .Field-RadioLabel {
                                align-items: flex-start;

                                @include narrow-desktop {
                                    width: 140%;
                                }

                                @include tablet {
                                    width: 140%;
                                }

                                @include mobile {
                                    text-align: start;
                                }

                                &:not([class*='_isDisabled']):hover {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
        
                &-Fields {
                    margin-block-end: 0;
                    width: 65%;
                    display: inline-block;
                    vertical-align: middle;

                    @include mobile {
                        width: 100%;
                    }
                }
            }
        
            .Button {
                width: 32%;
                height: 40px;
                color: #000;
                background-color: #{$white};
                border-color: #{$white};
                display: inline-block;
                vertical-align: top;
                margin-inline-start: 3%;
                margin-block-start: 9px;

                @include tablet {
                    margin-block-start: 5px;
                }

                @include mobile {
                    display: block;
                    margin: 0 auto;
                    margin-inline-start: auto;
                    margin-block-start: 15px;
                    width: 205px;
                    height: 43px;
                    font-size: 14px;
                }

                &:hover {
                    background-color: #DBFEC6;
                    border-color: #DBFEC6;
                }
            }
        }
    }
}



