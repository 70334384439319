
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


.ImageZoomPopup {
    height: 100%;

    
    &.Popup {
        height: 100%;
    }
    
    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;


        .Popup-CloseBtn {
            inset-inline-end: 10px;
            inset-block-start: 10px;
        }
    }

    .ProductGallery {
        height: 100%;
        width: 100%;

        &-SliderWrapper {
            height: 100%;
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
