
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** new component Sidea */

.TrashIcon {
    cursor: pointer;
    stroke: $black;

    &:hover {
        stroke: var(--primary-base-color);
    }
}
