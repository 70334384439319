
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override style */

:root {
    --header-top-menu-height: 50px;
    --header-logo-height: 50px;
    --header-logo-width: 225px;
    --topmenu-height: 60px;

    @include ultra-narrow-desktop {
        --header-top-menu-height: 40px;
    }

    @include mobile {
        --topmenu-height: 52px;
    }
}

.Header {
    position: relative;
    border-bottom: none;

    &-TopMenu {
        background-image: var(--background-green); /* la variabile è definita in _custom.scss */
        display: flex;
        justify-content: space-between;
        height: var(--header-top-menu-height);
        margin: auto;
        max-width: 100%;
        padding-inline: 0;
        padding-block-start: 0;
        padding-block-end: 0;
        min-height: var(--topmenu-height);
        font-size: 1.2rem;

        @include mobile {
            background-position: 10%;
            min-height: 52px;
            font-size: 10px;
        }

        .topbanner {
            height: 100%;

            div {
                height: 50%;

                &:last-child {
                    background-color: $black;
                    color: #fff;

                    p {
                        color: #fff;
                    }
                }
            }

            p {
                font-size: 1.2rem;
                line-height: calc(var(--topmenu-height) / 2);
                margin-block-end: 0;
                text-align: center;
                text-transform: uppercase;

                @include mobile {
                    font-size: 10px;
                }
            }
        }

        .container-full {
            margin-inline-start: 0;
        }
    }

    &-Switcher {
        align-items: center;
        display: flex;
    }

    &-Nav {
        @include desktop {
            align-items: start;
            max-width: 100%;
            padding-inline: calc( (100% - var(--content-wrapper-nav-width)) / 2);
            height: auto;
            border-bottom: 1px solid var(--border-color-gray);
        }

        @include desktop-wide-1440 {
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 500px) 1fr;
        }

        @include narrow-desktop {
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 400px) 1fr;
        }

        @include ultra-narrow-desktop {
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 350px) 1fr;
        }
    }

    .NavigationTabs {
        @include mobile {
            &_isHidden {
                display: block;
            }
        }
    }

    &-MyAccount {
        button,
        a {
            @include desktop {
                cursor: pointer;
                vertical-align: top;
                height: 16px;
                line-height: 16px;
                font-weight: 500;
                font-size: 12px;
            }

            @include narrow-desktop {
                font-size: 11px;
            }

            @include tablet {
                font-size: 11px;
            }

            &:hover span {
                color: var(--primary-base-color);
            }
        }
    }

    &-MyAccountContainer {
        margin-inline-start: 0;
    }

    &-LogoWrapper {
        position: relative;
        width: auto;

        @include desktop {
            height: 100%;
        }

        .Image-Image {
            width: auto;
            height: auto;
            position: relative;

            @include desktop {
                inset-block-start: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            @include desktop-wide-1600 {
                max-width: 100%;
            }

            @include desktop-wide-1440 {
                max-width: 85%;
            }

            @include ultra-narrow-desktop {
                max-width: 90%;
            }
        }
    }

    &-IconsWrapper {
        margin-block-start: 11px;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;
        
        > div {
            padding-inline: 18px;
            border-right: 1px solid #A8A8A8;
            line-height: 16px;
            height: 16px;


            @include desktop-wide-1600 {
                padding-inline: 13px;
            }

            @include ultra-narrow-desktop {
                padding-inline: 8px;
            }

            @include tablet {
                padding-inline: 10px;
            }

            &:first-child {
                padding-inline-start: 0;
            }

            &:last-child,
            &:nth-last-child(2) {
                border-right: none;
            }
        }
    }

    &-CompareButtonWrapper,
    &-StoreButtonWrapper {
        margin-inline-start: 0;

        a {
            all: unset;
            cursor: pointer;

            @include narrow-desktop {
                font-size: 11px;
            }

            @include tablet {
                font-size: 11px;
            }

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-StoreButtonWrapper {
        @include tablet {
            min-width: 75px;
            padding-inline-end: 0;
        }

        svg {
            transform: scale(0.84);
            margin-block-start: -13px;
            margin-inline-end: 1px;

            @include tablet {
                transform: scale(0.7);
                vertical-align: bottom;
            }
        }
    }

    &-MinicartButtonWrapper span {
        color: var(--primary-base-color);
        font-size: 12px;
        font-weight: 700;

        @include narrow-desktop {
            font-size: 11px;
        }

        @include tablet {
            font-size: 11px;
        }

        &:hover {
            color: $black;
        }

        &.Header-MinicartItemCount {
            color: $white;
            inset-inline-start: -7px;
            inset-inline-end: auto;
            inset-block-start: -8px;

            @include tablet {
                inset-block-start: -4px;
                inset-inline-start: 0;
            }

            &::before {
                display: none;
            }
        }
    }
 
    &-Button {
        &_type {
            &_minicart {
                padding-inline-end: 0;
                
                @include desktop {
                    height: 23px !important; /* stylelint-disable-line declaration-no-important */
                    width: auto;
                    margin-inline-start: 0;
                }

                @include tablet {
                    min-width: 80px;
                    padding-inline-start: 0;
                }

                .CartIcon {
                    fill: var(--primary-base-color);
                    width: 21px;
                    height: 21px;
                    vertical-align: middle;
                }
            }
        }
    }


    .SearchField {
        @include mobile {
            display: block;
            max-width: 91%;
            width: 91%;
            margin-block-start: 0;
        }
    }

    .Menu {
        @include desktop {
            &-MainCategories {
                padding-inline-start: 0;
                width: auto;
            }

            &-MenuWrapper {
                margin-block-start: 0;
                max-width: var(--content-wrapper-large);
            }

            &-ItemList {
                height: var(--header-top-menu-height);

                &_type_subcategory {
                    height: auto;
                }
            }

            &-RightButtonsWrapper {
                --button-height-mini: 35px;
                
                @include ultra-narrow-desktop {
                    --button-height-mini: 30px;
                }

                position: absolute;
                inset-inline-end: 0;
                inset-block-start: calc((100% - var(--button-height-mini)) / 2);

                .Button {
                    height: var(--button-height-mini);
                    min-width: 172px;
                    margin-inline-start: 3px;

                    @include desktop-wide-1440 {
                        min-width: 150px;
                    }

                    @include narrow-desktop {
                        min-width: 150px;
                    }

                    @include ultra-narrow-desktop {
                        min-width: 120px;
                        height: 30px;
                    }

                    @include tablet {
                        min-width: 120px;
                        height: 30px;
                    }

                    &.newProducts {
                        background-color: $default-secondary-light-color;
                        border-color: $default-secondary-light-color;
                        color: $default-secondary-base-color;

                        &:hover {
                            background-color: $default-secondary-base-color;
                            border-color: $default-secondary-base-color;
                            color: $white;
                        }
                    }

                    &.sales {
                        background-color: $default-primary-light-color;
                        border-color: $default-primary-light-color;
                        color: $default-primary-base-color;

                        &:hover {
                            background-color: $default-primary-base-color;
                            border-color: $default-primary-base-color;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: 0;
    }
}
