
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Style component Sidea */

.Infosped-Popup {
    .Popup {
        &-Content {
            background-color: #fff;
            border-radius: 0;
            min-width: 50%;
            overflow-x: hidden;
            max-height: unset;
            overflow-y: auto;
        }
    }
}
