
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
                padding: 16px;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        z-index: 110;
        width: 100%;

        .NoResults {
            padding-inline-start: 20px;
        }

        @include desktop {
            padding-block-start: 20px;
            padding-block-end: 10px;
            padding-inline-end: 0;
            position: absolute;
            inset-block-start: 60px;
            width: 100%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
            border: 1px solid #D1D3D6;

            ul {
                max-height: 40vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                    margin-inline-end: 6px;
                }
                
                &::-webkit-scrollbar-track {
                    background-color: #c9c9c9;
                    border-radius: 3px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    background-color: #656565;
                }

                .SearchItem {
                    padding: 3px 12px;

                    &-Wrapper {
                        grid-template-columns: 40px calc(100% - 60px);
                    }

                    h4 {
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 1.3;
                    }
                }
            }
        }
    }
}
