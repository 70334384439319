
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.Menu {
    &-Item {
        padding-inline-start: 0;
        margin-inline-end: 25px;

        @include desktop {
            height: auto;
            margin: 0;
        }

        @include ultra-narrow-desktop {
            margin-inline-end: 10px;
        }

        @include tablet {
            margin-inline-end: 10px;
        }

        @include mobile {
            margin-inline-end: 0;

            &:last-child {
                margin-block-end: 6px;
            }
        }

        &Caption {
            color: var(--color-black);

            &:hover {
                color: var(--primary-base-color);
            }

            @include mobile {
                margin-inline: 0;
            }

            @include mobile {
                padding-block: 7px;
            }

            &_isSecondLevel {
                font-weight: 500;
            }

            &_type {
                &_main {
                    @include desktop {
                        margin: 0 0 0;
                    }

                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 50px;
                    letter-spacing: 0.06rem;

                    @include ultra-narrow-desktop {
                        font-size: 11px;
                        line-height: 41px;
                    }

                    @include mobile {
                        text-transform: none;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 1.3;
                    }
                }

                &_subcategory {
                    line-height: 20px;

                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        margin-block-start: 12px;
                        display: inline-block;
                    }
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 25px;
                margin-block-end: 0;
            }

            @include tablet {
                margin-inline-start: 20px;
            }
        }

        &List {
            &_type_subcategory {
                @include mobile {
                    padding-block-start: 5px;
                    padding-block-end: 20px;
                }
            }
        }
    }

    &-Link {
        cursor: pointer;

        @include ultra-narrow-desktop {
            font-size: 12px;
        }

        @include tablet {
            font-size: 12px;
        }

        &_isHovered {
            .Menu-ItemCaption_type_main {
                &::after {
                    @include desktop {
                        content: "";
                        display: block;
                        position: absolute;
                        inset-block-end: 7px;
                        width: 100%;
                        height: 5px;
                        background-color: $black;
                    }

                    @include ultra-narrow-desktop {
                        height: 3px;
                    }

                    @include tablet {
                        height: 3px;
                    }
                }
            }

            .Menu-ItemCaption_type_main.special_button_menu {
                &::after {
                    @include desktop {
                        content: "";
                        display: block;
                        position: absolute;
                        inset-block-end: 7px;
                        width: 80%;
                        height: 5px;
                        background-color: #78A35E;
                    }
                } 
            }
        }
    }

    &-Sub {
        &Menu {
            @include mobile {
                margin-block-end: 35px;
            }

            &_isVisible {
                background: transparent;

                @include mobile {
                    margin-block-end: 0;
                    padding-inline-start: 20px;
                }
            }

            .Menu-ItemCaption {
                @include ultra-narrow-desktop {
                    padding-block-end: 2px;
                }

                @include mobile {
                    padding-block: 4px;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            inset-inline-start: calc(((-100vw + var(--content-wrapper-large)) / 2));
            padding-inline: calc((100vw - var(--content-wrapper-large)) / 2);
            position: absolute;
            background: #fff;
            width: 100vw;
            z-index: 999;
            border-bottom: 1px solid var(--border-color-gray);
        }
    }

    &-SubCategories {
        padding: 20px 0 30px;

        @include ultra-narrow-desktop {
            padding: 5px 0;
        }
    }

    &-ExpandedState {
        --plus-minus-icon-size: 24px;

        inset-block-start: 2px;
    }

    &-RightButtonsWrapper {
        @include mobile {
            .Button {
                background-color: #fff;
                border: none;
                color: $black;
                display: block;
                font-size: 16px;
                line-height: 1.3;
                padding: 0;
                height: auto;
                letter-spacing: 0.06rem;
                padding-block: 7px;
                margin-block-end: 6px;

                &.newProducts {
                    color: $default-secondary-base-color;
                }

                &.sales {
                    color: $default-primary-base-color;
                }
            }
        }
    }

    @include mobile {
        &-Additional {
            margin-block-start: 40px;
            display: flex;
            flex-direction: column;
            row-gap: 19px;
            font-size: 14px;
            font-weight: 500;
    
            .Menu-Button {
                font-size: 14px;
                font-weight: 500;

                svg {
                    vertical-align: middle;
                    margin-inline-end: 13px;
                }
            }

            .Header-StoreButtonWrapper a::before {
                margin-block-start: 0;
                margin-inline-end: 13px;
            }
        }
    }
}

.MenuPage {
    @include desktop {
        display: none;
    }
    
    @include mobile {
        position: absolute;
        inset-block-start: 0;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        padding-block-start: 60px;
        padding-inline: 35px;
        margin-block-start: 0px;
        background-color: $white;
        z-index: 999;
        transform: translateX(-100%);
        transition: all 200ms;
    }

    &.isVisible {
        transform: translateX(0);
    }

    &-Close {
        @include mobile {
            position: fixed;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 50px !important; /* stylelint-disable-line declaration-no-important */
            height: 50px;
            text-align: center;
            background-color: #fff;
            z-index: 9999;
            display: none;

            svg {
                vertical-align: middle;
            }

            &.isVisible {
                display: block;
            }
        }

        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }
    }
}

.Menu-ItemCaption_type_main.special_button_menu {
    background-color: #DBFEC6;
    border-color: #DBFEC6;
    color: #78A35E;
    padding: 0 15px;

    @include mobile {
        padding: 7px 0px;
    }
}
