/** override sidea */

:root {
    --default-input-padding: 15px 20px 14px;
    --input-background-color: var(--imported_input_background_color, #fff);
    --input-border-color: var(--imported_input_border_color, #{$default-neutral-dark-color});
    --input-border-color-focus: var(--primary-base-color);
    --input-border-radius: var(--imported_input_border_radius, 0);
    --input-padding: var(--imported_input_padding, var(--default-input-padding));
    --input-color: var(--imported_input_color, #{$black});
    --input-type-number-width: 28px;
    --input-font-size: 1.4rem;
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: var(--input-color);
    padding: var(--input-padding);
    font-size: var(--input-font-size);
    appearance: none;
    caret-color: var(--primary-base-color);

    &:focus {
        border: 1px solid var(--input-border-color-focus);
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: var(--input-background-color) !important;
    }
}

input {
    height: 60px;
}

textarea {
    width: 300px;

    @include mobile {
        width: 100%;
    }
}

