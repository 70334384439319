
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: #000;
    --search-field-background: #000;
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: #000;
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #E3E3E3;
}

.SearchField {
    @include desktop {
        max-width: var(--search-bar-max-width);
        margin: 0 auto;
        margin-block: 42px 36px;
    }

    @include ultra-narrow-desktop {
        margin-block: 30px 30px;
    }

    @include tablet {
        max-width: 330px;
    }
    
    &-Input,
    &-Input:focus {
        border: 1px solid var(--border-color-gray);
        border-bottom: 4px solid #000;
        height: 55px;
        font-size: 12px;
        font-weight: 400;
        padding-block-start: 30px;

        @include ultra-narrow-desktop {
            height: 45px;
            padding-block-start: 25px;
            padding-inline-start: 15px;
        }

        @include tablet {
            height: 45px;
            padding-block-start: 25px;
            padding-inline-start: 15px;
        }

        @include mobile {
            border-bottom: 3px solid #000;
            height: 36px;
            padding: 0 12px;
        }

        &::placeholder {
            color: #000;
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        inset-block-start: 35%;

        @include ultra-narrow-desktop {
            inset-block-start: 27%;
            inset-inline-end: 11px;
        }

        @include tablet {
            inset-block-start: 27%;
            inset-inline-end: 11px;
        }

        @include mobile {
            inset-block-start: 15%;
            inset-inline-end: 8px;
            
            svg {
                width: 20px; 
            }
        }
    }

    &-CloseIcon {
        height: 20px;
        width: 20px;

        svg {
            width: 17px;
        }
    }
}
