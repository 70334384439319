
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Component Sidea Menu basato sull'alberatura delle categorie (basato su componente Menu) */

:root {
    --menu-item-height: 20px;
    --menu-total-height: 53px;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    &-CompareLink {
        color: var(--color-black);
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        display: flex;
        align-items: center;

        .CompareIcon {
            margin-inline-end: 12px;

            &:hover {
                fill: var(--color-black);
            }
        }

        &Wrapper {
            border-block-end: 1px solid var(--primary-divider-color);
            padding-block-start: 17px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin: 0 16px;

            @include mobile {
                padding: 13px 0;
            }
        }
    }

    &-CompareCountInMenu {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        inset-block-start: -1px;
        margin-inline-start: auto;
    }

    &-ExpandedState {
        --plus-minus-icon-size: 24px;

        inset-block-start: 2px;
        display: inline-block;
        float: inline-end;

        &:dir(rtl) {
            float: inline-start;
        }

        position: absolute;
        inset-inline-end: 0;

        @include desktop {
            display: none;
        }
    }

    &-Item {
        padding-inline-start: 0;
        margin-inline-end: 25px;

        @include desktop {
            height: auto;
            margin: 0;
        }

        @include ultra-narrow-desktop {
            margin-inline-end: 10px;
        }

        @include tablet {
            margin-inline-end: 10px;
        }

        @include mobile {
            margin-inline-end: 0;

            &:last-child {
                margin-block-end: 6px;
            }
        }

        &::before {
            content: none;
        }

        &Caption {
            color: var(--color-black);

            &:hover {
                color: var(--primary-base-color);
            }

            @include mobile {
                margin-inline: 0;
                padding-block: 7px;
            }

            &_isSecondLevel {
                font-weight: 500;
            }

            &_type {
                &_main {
                    @include desktop {
                        margin: 0 0 0;
                    }

                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 50px;
                    letter-spacing: 0.06rem;

                    @include ultra-narrow-desktop {
                        font-size: 11px;
                        line-height: 41px;
                    }

                    @include mobile {
                        text-transform: none;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 1.3;
                    }
                }

                &_subcategory {
                    line-height: 20px;

                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        margin-block-start: 12px;
                        display: inline-block;
                    }
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 25px;
                margin-block-end: 0;
            }

            @include tablet {
                margin-inline-start: 20px;
            }
        }

        &List {
            @include desktop {
                display: flex;

                .Menu-SubMenu & {
                    display: block;
                }
            }

            &_type_subcategory {
                @include mobile {
                    padding-block-start: 5px;
                    padding-block-end: 20px;
                }
            }
        }
    }

    &-Link {
        cursor: pointer;
        display: contents;
        font-weight: 400;
        margin-block: 8px;
        margin-inline: 0 16px;

        @include ultra-narrow-desktop {
            font-size: 12px;
        }

        @include tablet {
            font-size: 12px;
        }

        @include mobile {
            text-align: start;
        }

        &_isHovered {
            .Menu-ItemCaption_type_main {
                &::after {
                    @include desktop {
                        content: "";
                        display: block;
                        position: absolute;
                        inset-block-end: 7px;
                        width: 100%;
                        height: 5px;
                        background-color: $black;
                    }

                    @include ultra-narrow-desktop {
                        height: 3px;
                    }

                    @include tablet {
                        height: 3px;
                    }
                }
            }
        }
    }

    &-MainCategories {
        margin: 0 auto;

        @include desktop {
            width: fit-content;

            .Menu-Link {
                font-weight: 700;
            }

            .menu-highlight {
                background-color: #FEE2FF;
                border-color: #FEE2FF;
                color: #A84EA9;
                padding-inline-start: 15px;
                padding-inline-end: 15px;
                display: table-cell;
                
                figcaption {
                    color: #A84EA9;
                }
            }
        }

        @include mobile {
            .menu-highlight {
                .Menu-ItemCaption_type_main {
                    color: #A84EA9;
                }
            }
        }
    }

    &-MenuWrapper {
        @include desktop {
            margin-block-start: 18px;
        }
    }

    &-SubCategories {
        padding: 20px 0 30px;

        @include ultra-narrow-desktop {
            padding: 5px 0;
        }
        
        @include desktop {
            .menu-highlight {
                border-bottom: 1px solid black;
                /*padding-inline-start: 5px;
                padding-inline-end: 5px;*/
                display: table-cell;
            }
        }
    }

    &-Sub {
        &ItemWrapper {
            > .Menu-Link {
                @include desktop {
                    font-weight: 700;
                }
            }

            break-inside: avoid;
            overflow: hidden;

            @include mobile {
                .menu-highlight {
                    .Menu-ItemCaption_isSecondLevel {
                        border: 1px solid black;
                        padding-inline-start: 5px;
                        padding-inline-end: 5px;
                    }
                }
            }
        }

        &Menu {
            display: none;
            margin-block-end: 28px;

            @include mobile {
                margin-block-end: 35px;
            }

            &_isVisible {
                background: transparent;
                display: block;
                padding-inline: 16px;

                @include mobile {
                    margin-block-end: 0;
                    padding-inline-start: 20px;
                }
            }

            @include desktop {
                background: none;
                display: block;
                padding: 0;
            }

            .Menu-SubMenu & {
                padding-block-end: 0;
            }

            .Menu-ItemCaption {
                @include desktop {
                    padding-block-end: 8px;
                }

                @include ultra-narrow-desktop {
                    padding-block-end: 2px;
                }

                @include mobile {
                    padding-block: 4px;
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    columns: 4;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            inset-inline-start: calc(((-100vw + var(--content-wrapper-large)) / 2));
            padding-inline: calc((100vw - var(--content-wrapper-large)) / 2);
            position: absolute;
            background: #fff;
            width: 100vw;
            z-index: 999;
            border-bottom: 1px solid var(--border-color-gray);
        }
    }

    &-RightButtonsWrapper {
        @include mobile {
            .Button {
                background-color: #fff;
                border: none;
                color: $black;
                display: block;
                font-size: 16px;
                line-height: 1.3;
                padding: 0;
                height: auto;
                letter-spacing: 0.06rem;
                padding-block: 7px;
                margin-block-end: 6px;

                &.newProducts {
                    color: $default-secondary-base-color;
                }

                &.sales {
                    color: $default-primary-base-color;
                }
            }
        }
    }

    @include mobile {
        &-Additional {
            margin-block-start: 40px;
            display: flex;
            flex-direction: column;
            row-gap: 19px;
            font-size: 14px;
            font-weight: 500;
    
            .Menu-Button {
                font-size: 14px;
                font-weight: 500;

                svg {
                    vertical-align: middle;
                    margin-inline-end: 13px;
                }
            }

            .Header-StoreButtonWrapper a::before {
                margin-block-start: 0;
                margin-inline-end: 13px;
            }
        }
    }
}

.MenuPage {
    @include desktop {
        display: none;
    }
    
    @include mobile {
        position: fixed;
        inset-block-start: 0;
        width: 100%;
        min-height: calc(100vh - 45px);
        overflow-y: scroll;
        padding-block-start: 40px;
        padding-inline: 35px;
        margin-block-start: 47px;
        background-color: $white;
        z-index: 999;
        transform: translateX(-100%);
        transition: all 200ms;
    }

    &.isVisible {
        transform: translateX(0);
    }

    &-Close {
        @include mobile {
            position: fixed;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 50px !important; /* stylelint-disable-line declaration-no-important */
            height: 50px;
            text-align: center;
            background-color: #fff;
            z-index: 9999;
            display: none;

            svg {
                vertical-align: middle;
            }

            &.isVisible {
                display: block;
            }
        }

        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }
    }
}
