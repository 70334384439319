
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.Breadcrumb {
    $crumb-padding: 10px;

    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding-inline-end: $crumb-padding;

    @include mobile {
        font-size: 11px;
        padding-inline-end: 5px;
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg) brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 10px;
        height: 8px;
        inset-block-end: 0;

        @include mobile {
            margin-inline-start: 5px;
        }
    }

    &:last-child {
        margin-block-end: 0;

        a {
            color: $black;
            font-weight: 700;
        }

        .ChevronIcon {
            display: none;
        }

        :link {
            pointer-events: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-weight: 400;
        font-size: 12px;

        @include tablet {
            font-size: 10px;
        }

        @include mobile {
            font-size: 10px;
        }

        &:hover {
            color: var(--link-color);
        }

        &-Name {
            color: inherit;
        }
    }
}
