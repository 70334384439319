/** extend sidea */
/* stylelint-disable declaration-no-important */

.banner {
    position: relative;
    margin-block-start: 120px;

    @include tablet {
        min-height: unset !important;
        padding: 50px;
    }

    @include mobile {
        margin-block-start: 72px;
        min-height: 180px !important;
        padding: 0 50px;
    }

    .evidenza h2, .evidenza p {
        font-weight: 300;

        @include mobile {
            line-height: 1.2;
            margin-block-start: 29px;
            margin-block-end: 10px;

            strong {
                display: block;
            }
        }
    }

    @include mobile {
        .p18, .p18 p {
            font-size: 12px;
            line-height: 1.3;
        }

        p {
            margin-block-end: 0;
        }
    }

    &.promo {
        background-image: var(--background-rosa);

        @include mobile {
            background-position: 50% -20px !important;
        }
    }

    &.collect {
        background-image: var(--background-azzurro);

        @include mobile {
            background-position: 50% -20px !important;
        }
    }

    .banner-icon {
        position: absolute;
        inset-block-start: -120px;
        inset-inline: 0;

        @include tablet {
            inset-block-start: -90px;
        }

        @include mobile {
            inset-block-start: -72px;
        }

        img {
            width: auto;
            height: auto;

            @include tablet {
                max-width: 130px;
            }
        }
    }

    .pagebuilder-button-primary:not(:hover) {
        background-color: $black;
        border-color: $black;
    }
}

