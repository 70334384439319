
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Component Sidea - Popup selezione pickup store in pagina dettaglio prodotto */

.StorePickup-Popup {
    justify-content: flex-end;
    width: 100vw;
    height: 100vh;
    inset-block-start: 0;
    transition: transform 200ms ease-in-out;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;

    .Popup {
        &-Content {
            background-color: #fff;
            border-radius: 0;
            min-width: 410px;
            overflow-x: hidden;
            max-height: unset;
            position: relative;
            z-index: 10;
            width: 410px;
            padding: 43px;
            height: 100%;
            overflow-y: scroll;

            @include mobile {
                width: 100%;
                min-width: unset;
            }
        }

        &-CloseBtn {
            position: relative;
            inset-block-start: 0;
            inset-inline-end: auto;
            inset-inline-start: 0;
            z-index: 1;
        }

        &-Header {
            padding-block-start: 0;
            margin-block-end: 60px;

            @include mobile {
                margin-block-end: 10px;
            }
        }
    }

    &_isVisible {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateY(0%);
        transition: transform 200ms ease-in-out;
    }

    .StorePickup {
        &-List {
            font-weight: 700;
            font-size: 1.4rem;
            margin-block-start: 45px;

            @include mobile {
                margin-block-start: 35px;
            }

            p {
                font-size: 1.4rem;
                font-weight: 700;
            }
        }

        &-SingleStore {
            margin-block-end: 30px;

            .SingleStore {
                &-Info {
                    border: 1px solid #CCCCCC;
                    padding: 20px 10px;
                    padding-inline-start: 45px;
                    margin-block-end: 10px;
                }
    
                &-Orari {
                    font-weight: 400;
                    margin-block-end: 0;

                    .Orari-Button {
                        font-weight: 400;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .SingleStore-Description {
                        max-height: 0;
                        -webkit-transition: max-height 0.5s; 
                        -moz-transition: max-height 0.5s; 
                        -ms-transition: max-height 0.5s; 
                        -o-transition: max-height 0.5s; 
                        transition: max-height 0.5s; 
                        overflow-y: hidden;
                    }

                    &:hover {
                        .SingleStore-Description {
                            max-height: 150px;
                            overflow-y: visible;
                        } 
                    }
                }
            }

            &.CollectExpressAvailable {
                .SingleStore {
                    &-Info {
                        border: 2px solid $default-primary-dark-color;
                    }
                }

                .Button.primary-black.small {
                    background-color: var(--button-background);
                    border-color: var(--button-background);

                    &:hover {
                        background-color: var(--button-hover-background);
                        border-color: var(--button-hover-background);
                    }
                }
            }
        }

        &-Selection {
            &.Button {
                width: 100%;
            }
        }

        &-Name {
            display: none;
        }

        &-Address {
            span.City {
                text-transform: uppercase;
            }

            svg {
                height: 21px;
                width: 17px;
                vertical-align: middle;
                position: absolute;
                inset-inline-start: -30px;
                margin-inline-end: 13px;
            }
        }

        &-CollectExpress {
            color: $default-primary-base-color;
            margin-block-end: 30px;

            p {
                margin-block-end: 3px;
                color: $default-primary-base-color;
            }

            .CollectExpress {
                &-Note {
                    color: $black;
                }
            }

            svg {
                vertical-align: middle;
                position: absolute;
                inset-inline-start: -30px;
                margin-inline-end: 13px;
            }
        }
    }

    .SingleStore-Selection {
        p {
            strong {
                display: block;
            }
        }
        
        .ResetSelection {
            cursor: pointer;
            color: #9b9b9b;

            .CloseIcon {
                width: 15px;
                height: 13px;
                vertical-align: middle;
                margin-inline-end: 5px;
            }

            &:hover {
                color: $black;

                .CloseIcon {
                    stroke: $black;
                }
            }
        }
    }
}
