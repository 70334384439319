
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        margin-block: 0;

        &-Wrapper {
            margin-block: 0;
            padding-inline: 0;

            @include desktop {
                max-width: var(--content-wrapper-large);
            }
        }

        .product-showcase {
            padding-block-end: 90px;

            @include ultra-narrow-desktop {
                padding-block-end: 60px;
            }

            @include mobile {
                padding-block-end: 40px;
            }
        }
    }

    .product-showcase {
        padding-block-end: 90px;

        @include ultra-narrow-desktop {
            padding-block-end: 60px;
        }

        @include mobile {
            padding-block-end: 40px;
        }
    }
}

