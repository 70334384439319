
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    @include desktop {
        background-image: var(--sfondo-negozi);
        background-repeat: no-repeat;
        background-position: left 100px;
    }

    @include mobile {
        background-image: var(--sfondo-negozi);
        background-repeat: no-repeat;
        background-position: -200px 0;
        background-size: 130%;
    }

    .TelLink {
        font-size: 2.4rem;
        font-weight: 300;
    }

    .ContentWrapper {
        margin-inline-start: 8.3%;
        margin-inline-end: 18.75%;
        padding: 0%;

        @include desktop-wide-1600 {
            margin-inline-end: 10.3%;
        }

        @include ultra-narrow-desktop {
            margin-inline-start: 40px;
            margin-inline-end: 40px;
        }

        @include tablet {
            margin-inline-start: 32px;
            margin-inline-end: 32px;
        }

        @include mobile {
            margin-inline-start: 18px;
            margin-inline-end: 18px;
        }
    }

    &-Heading {
        height: fit-content;
        margin-block-start: 50px;
        margin-block-end: 50px;
        font-size: 7.2rem;
        font-weight: 300;

        @include desktop-wide-1600 {
            font-size: 6.5rem;
        }
    
        @include desktop-wide-1440 {
            font-size: 6rem;
            margin-inline-start: -50px;
        }
    
        @include narrow-desktop {
            font-size: 5.8rem;
        }
    
        @include ultra-narrow-desktop {
            font-size: 4.5rem;
        }
    
        @include tablet {
            font-size: 4.5rem;
            margin-block-start: 35px;
        }
    
        @include narrow-tablet {
            font-size: 4rem;
        }

        @include mobile {
            margin-block-start: 10px;
            margin-block-end: 20px;
            font-size: 2.7rem;
        }

        strong {
            font-weight: 600;
            line-height: 1.2;
        }
    }

    &-Divider {
        border-bottom: 5px solid #272727;
        width: 110px;
        margin-block-end: 40px;

        @include desktop-wide-1440 {
            margin-inline-start: -50px;
        }

        @include mobile {
            border-bottom: 3px solid #272727;
            width: 69px;
            margin-block-end: 30px;
        }
    }

    &-Info {
        padding-inline-start: 110px;
        margin-block-end: 40px;

        @include desktop-wide-1440 {
            padding-inline-start: 32px;
        }

        @include ultra-narrow-desktop {
            padding-inline-start: 32px;
        }

        @include tablet {
            padding-inline-start: 32px;
        }

        @include mobile {
            padding-inline-start: 0;
            margin-block-end: 30px;
        }
    }

    &-Number {
        font-size: 2.4rem;
        font-weight: 300;
        margin-block-end: 0;

        @include ultra-narrow-desktop {
            font-size: 2rem;
        }

        @include tablet {
            font-size: 2rem;
        }

        @include mobile {
            font-size: 1.6rem;
            margin-block-end: 15px;
        }

        svg {
            margin-inline-end: 20px;

            @include mobile {
                margin-inline-end: 10px;
            }
        }
    }

    &-Email {
        font-size: 2.4rem;
        font-weight: 300;

        @include ultra-narrow-desktop {
            font-size: 2rem;
        }

        @include tablet {
            font-size: 2rem;
        }

        @include mobile {
            font-size: 1.6rem;
        }

        svg {
            margin-inline-end: 20px;
            vertical-align: middle;

            @include mobile {
                margin-inline-end: 10px;
            }
        }
    }

    &-ColumnWrapper {
        display: flex;
        margin-block-end: 80px;
        margin-inline-start: 110px;

        @include desktop-wide-1440 {
            margin-inline-start: 32px;
        }
        
        @include ultra-narrow-desktop {
            margin-inline-start: 32px;
            margin-block-end: 60px;
        }

        @include tablet {
            margin-block-end: 60px;
            margin-inline-start: 32px;
        }

        @include mobile {
            display: block;
            padding: 12px 0;
            margin-inline-start: 0;
            margin-block-end: 40px;
        }

        .mod {
            max-width: 260px;

            @include mobile {
                max-width: 100%;
            }
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-inline-end: 18px;

            @include ultra-narrow-desktop {
                margin-inline-end: 0;
            }

            @include tablet {
                margin-inline-end: 0;
            }

            @include mobile {
                margin-inline-end: 0;
            }
        }
    }

    .CmsBlock-Wrapper p {
        font-size: 3.3rem;
        font-weight: 300;
        line-height: 1.2;

        @include ultra-narrow-desktop {
            font-size: 2.8rem;
        }

        @include tablet {
            font-size: 2.8rem;
        }

        @include mobile {
            font-size: 2.4rem;
            margin-block-end: 25px;
        }

        strong {
            font-weight: bold;
        }
    }

    .Form {
        @include mobile {
            .col-md-6 {
                padding-inline-start: 0;
                padding-inline-end: 0;
                margin-block-end: 10px;
            }
    
            .Field {
                margin-block-start: 10px;
            }

            .FieldSelect-Select {
                margin-block-start: 0;
            }
        }

        .Button {
            @include mobile {
                width: 100%;
            }
        }
    }
}
