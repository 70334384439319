
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.Router {
    &-MainItems {
        min-height: calc(100vh - var(--header-total-height) + 10px);

        @include mobile {
            min-height: fit-content;
            overflow-x: hidden;
        }
    }

    &-Loader {
        height: calc(100vh - var(--header-total-height) - 10px);
        inset-block-start: calc(var(--header-total-height) + 10px);
    }
}
