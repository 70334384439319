/** override sidea  */
/* stylelint-disable declaration-no-important */

:root {
    --default-button-padding: 20px;
    --default-button-radius: 0;
    --default-button-height: 60px;
    --default-text-decoration: none;
    --button-border-width: 2px;
    // Primary button
    --button-background: var(--imported_buttons_background_color, var(--primary-base-color));
    --button-border: var(--imported_buttons_border_color, var(--primary-base-color));
    --button-border-radius: var(--imported_buttons_border_radius, var(--default-button-radius));
    --button-color: var(--imported_buttons_color, #{$white});
    --button-height: var(--imported_buttons_height, var(--default-button-height));
    --button-padding: var(--imported_buttons_padding, var(--default-button-padding));
    --button-text-decoration: var(--imported_buttons_text_decoration, var(--default-text-decoration));
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, #{$default-neutral-dark-color});
    --button-hover-border: var(--imported_hoverButtons_border_color, #{$default-neutral-dark-color});
    --button-hover-border-radius: var(--imported_hoverButtons_border_radius, var(--default-button-radius));
    --button-hover-color: var(--imported_hoverButtons_color, #{$white});
    --button-hover-height: var(--imported_hoverButtons_height, var(--default-button-height));
    --button-hover-padding: var(--imported_hoverButtons_padding, var(--default-button-padding));
    --button-hover-decoration: var(--imported_hoverButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hollow)
    --hollow-button-background: var(--imported_hollowButtons_background_color, $white);
    --hollow-button-border: var(--imported_hollowButtons_border_color, var(--primary-base-color));
    --hollow-button-border-radius: var(--imported_hollowButtons_border_radius, var(--default-button-radius));
    --hollow-button-color: var(--imported_hollowButtons_color, var(--primary-base-color));
    --hollow-button-height: var(--imported_hollowButtons_height, var(--default-button-height));
    --hollow-button-padding: var(--imported_hollowButtons_padding, var(--default-button-padding));
    --hollow-button-text-decoration: var(--imported_hollowButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hover)
    --hollow-button-hover-background: var(--imported_hollowHoverButtons_background_color, #{$white});
    --hollow-button-hover-border: var(--imported_hollowHoverButtons_border_color, #{$default-neutral-dark-color});
    --hollow-button-hover-border-radius: var(--imported_hollowHoverButtons_border_radius, var(--default-button-radius));
    --hollow-button-hover-color: var(--imported_hollowHoverButtons_color, var(--primary-dark-color));
    --hollow-button-hover-height: var(--imported_hollowHoverButtons_height, var(--default-button-height));
    --hollow-button-hover-padding: var(--imported_hollowHoverButtons_padding, var(--default-button-padding));
    --hollow-button-hover-text-decoration: var(--imported_hollowHoverButtons_text_decoration, var(--default-text-decoration));
}

.pagebuilder-button-primary,
.pagebuilder-button-secondary {
    @include button; // importa il mixin nel file _button in abstract

    text-transform: none;
    font-size: 12px;
    width: 100%;
    height: 43px;
    min-width: 225px;
    max-width: 472px;

    @include mobile {
        font-size: 14px;
        max-width: 205px;
        min-width: 205px;
    }
}

.pagebuilder-button-secondary {
    color: $black !important;
    border-color: $black;
    background-color: $white;

    span {
        color: $black;
    }

    &:hover {
        background-color: var(--hollow-button-hover-border);
        border-color: var(--hollow-button-hover-border);

        span {
            color: $white;
        }
    }
}

.Button {
    @include button; // importa il mixin nel file _button in abstract

    text-transform: none;
    font-size: 12px;

    &.primary,
    &.primary-black,
    &.secondary {
        font-size: 1.8rem;
        width: 100%;
        height: 60px;
        max-width: 472px;

        &.medium {
            max-width: 286px;
        }

        &.small {
            font-size: 12px;
            height: 43px;
            max-width: 324px;
        }
    }

    &.primary-black {
        background-color: $black;
        border-color: $black;
    }

    &.secondary {
        color: $black !important;
        border-color: $black;
        background-color: $white;

        span {
            color: $black;
        }

        &:hover {
            background-color: var(--hollow-button-hover-border);
            border-color: var(--hollow-button-hover-border);
            color: $white !important;

            span {
                color: $white;
            }
        }
    }
}

button {
    background-color: transparent;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--color-black);
    }
}
