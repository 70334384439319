
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.CloseIcon {
    stroke: #9e9e9e;
    cursor: pointer;

    &:hover {
        stroke: $black;
    }
}
