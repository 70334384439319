
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactForm {
    textarea {
        width: 100%;
        border: 1px solid #CCCCCC;
        min-height: 165px;
    }

    .Field-Wrapper_type_textarea {
        min-height: 165px;

        @include tablet {
            margin-block-start: 24px;
        }
    }

    .Field-Wrapper {
        width: 100%;
        max-width: 450px;
        height: 60px;

        @include narrow-tablet {
            max-width: 100%;
        }
    }

    .Field.Field_type_checkbox {
        font-size: 0.9rem;
        color: #707070;
    }

    .bottom-form {
        margin-block-start: 25px;
        max-width: 450px;

        .col-md-8 {
            @include ultra-narrow-desktop {
                width: 100%;
            }

            @include tablet {
                width: 100%;
            }
        }

        .col-md-4 {
            @include ultra-narrow-desktop {
                width: 50%;
            }

            @include tablet {
                width: 50%;
            }
        }
    }

    .p-0 {
        padding: 0;
    }

    .input-control {
        width: 48px !important; /* stylelint-disable-line declaration-no-important */
    }

    .col-md-4 {
        text-align: end;
    }

    .Field-CheckboxLabel {
        align-items: flex-start;
    }

    .Button {
        width: 115px;
        height: 40px;

        @include ultra-narrow-desktop {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }
    }

    input, select {
        border: 1px solid #CCCCCC;
    }

    select {
        height: 60px;
    }

    .Field-Wrapper:first-of-type .Field {
        margin-block-start: 0;
    }

    label {
        display: none;
    }
}
