
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: $white;
    --navigation-tabs-color: $black;
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-icon-stroke-width: 2px;

    position: relative;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-block-end: env(safe-area-inset-bottom);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    .hideOnScroll & {
        transform: translateY(0);
    }

    @include mobile {
        border-block-start: none;
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 12px;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;

        @include mobile {
            padding: 0;
        }
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: inherit;
        justify-content: center;
        align-items: center;

        &.Button-Menu,
        &.Button-Cart {
            width: 50px;
        }

        &.Button-Cart {
            @include mobile {
                .Header-MinicartButtonWrapper {
                    span.Header-MinicartItemCount {
                        inset-block-start: 5px;
                        inset-inline-start: 2px;
                    }
                }
            }
        }

        &.Logo {
            width: calc(100% - 100px);
        }
    }

    &-Icon {
        font-weight: bold;
    }

    .Image {
        &.Logo {
            width: 185px;
        }
    }
}
