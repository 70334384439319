/** extend sidea */
/* stylelint-disable declaration-no-important */

.chi-siamo-container {
    width: 100%;
    max-width: 890px;
    margin: auto;

    @include ultra-narrow-desktop {
        max-width: 700px;
    }

    @include tablet {
        max-width: 600px;
    }

    @include narrow-tablet {
        max-width: 600px;
    }
}

.h2-mod, .h2-mod p {
    font-size: 3.9rem;
    font-weight: bold;
    line-height: 1.8;
    max-width: 90%;

    @include desktop-wide-1440 {
        font-size: 3.7rem;
        max-width: 95%;
    }

    @include narrow-desktop {
        font-size: 3.5rem;
        max-width: 95%;
    }

    @include tablet {
        font-size: 3.2rem;
        max-width: 95%;
    }

    @include narrow-tablet {
        font-size: 2.4rem;
        max-width: 95%;
    }

    @include mobile {
        font-size: 2.7rem;
        max-width: 100%;
    }

    &.h2-mod p {
        line-height: 1.2;

        @include mobile {
            line-height: 1.3;
        }
    }

    &.var-green {
        color: $default-primary-base-color;
        text-transform: none;
    }

    &.var-fux {
        color: $default-secondary-base-color;
    }

    &.h30 {
        font-size: 3rem;
        line-height: 1.5;

        @include tablet {
            font-size: 2.5rem;
        }
    }

    &.light {
        font-weight: 500;
    }
}

.divider-cs {
    width: 13.4%;
    border-bottom: 5px solid $black;
    display: flex;
    align-self: center;

    @include mobile {
        width: 69px;
        border-bottom: 3px solid $black;

        &.mb-9 {
            margin-block-end: 35px !important;
        }
    }
}

