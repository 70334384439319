/** file di customizzazione sidea (nota: i file di stile dei singoli componenti hanno priorità)  */
/* stylelint-disable declaration-no-important */

/* Variabili globali */

:root {
    --background-green: url('images/sfondo-green.jpg');  //sfondo acquerello verde
    --background-green-top: url('images/sfondo-green-top.jpg');
    --background-rosa: url('images/sfondo-rosa.jpg');
    --background-azzurro: url('images/sfondo-azzurro.jpg');
    --background-acquerello: url('images/sfondo-acquerello.jpg');
    --newsletter-icon: url('images/ico-mail.png');
    --icon-map: url('images/ico-map.png');
    --arrow-next: url('images/arrow-next.svg');
    --arrow-prev: url('images/arrow-prev.svg');
    --loghi-secure: url('images/loghi-ecomm.png');
    --ico-sped: url('images/ico-sped.svg');
    --ico-shopper: url('images/ico-shopper.svg');
    --sfondo-negozi: url('images/sfondo-negozi.jpg');
    --sfondo-negozi-mobile: url('images/sfondo-negozi-mob.jpg');
    --icon-mail: url('images/ico-mail.svg');
    --icon-phone: url('images/ico-phone.svg');
}

/* generali */

html {
    scroll-behavior: smooth;
}

body iframe:last-of-type {
    display: none;
}

body {
    overflow-x: hidden;
    
    main.CmsPage {
        iframe {
            display: block !important;
        }
    }
}

h1 {
    &.ProductActions-Title {
        font-size: 3.2rem;
        font-weight: 300;

        @include narrow-desktop {
            font-size: 2.6rem;
        }

        @include tablet {
            font-size: 2.4rem;
        }

        @include mobile {
            font-size: 2.4rem;
        }
    }

    &.evidence {
        font-size: 7.2rem;
        font-weight: 600;

        @include desktop-wide-1440 {
            font-size: 6.5rem; 
        }

        @include narrow-desktop {
            font-size: 6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 5rem;
        }

        @include tablet {
            font-size: 5rem;
        }
    }

    &.light {
        font-weight: 300;
    }

    &.var-green {
        color: $default-primary-base-color;
        font-weight: bold;
        text-transform: none;
    }
}

h2 {
    &.evidence {
        font-size: 3.9rem;

        @include narrow-desktop {
            font-size: 3.3rem;
        }

        @include tablet {
            font-size: 3rem;
        }

        @include narrow-tablet {
            font-size: 3rem;
        }
    }

    &.text-up {
        text-transform: uppercase;
    }
}

h4 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.3;

    @include mobile {
        font-size: 16px;
    }
}

h5 {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1.3;

    @include mobile {
        font-size: 16px;
    }
}

h6 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.3;
}

.var-green {
    color: $default-primary-base-color;
}

.var-fux {
    color: $default-secondary-base-color;
}

.section-title {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);
    text-transform: var(--h1-text-transform);
    border-bottom: 5px solid #272727;
    padding-block-end: 10px;

    @include narrow-desktop {
        font-size: var(--h1-font-size-narrow);
        line-height: var(--h1-line-height-narrow);
    }

    @include tablet {
        font-size: var(--h1-font-size-tablet);
        line-height: var(--h1-line-height-tablet);
        margin-block-end: 10px;
    }

    @include mobile {
        font-size: var(--h1-font-size-mobile);
        border-bottom: 3px solid #272727;
        padding-block-end: 6px;
        margin-block-end: 8px;
        margin-block-start: 0;
    }
}

.evidenza p {
    color: var(--h2-color);
    font-style: var(--h2-font-style);
    margin-block-end: 18px;
    margin-block-start: 22px;
    text-transform: var(--h2-text-transform);
}

.evidenza,
.evidenza h2, .evidenza p {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);

    @include narrow-desktop {
        font-size: var(--h1-font-size-narrow);
        line-height: var(--h1-line-height-narrow);
    }

    @include tablet {
        font-size: var(--h1-font-size-tablet);
        line-height: var(--h1-line-height-tablet);
    }

    @include mobile {
        font-size: var(--h2-font-size);
    }
}

.txt-light {
    font-weight: 300;
}

.no-pad {
    padding-inline: 0 !important;
}

.p24, .p24 p {
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: 300;

    @include desktop-wide-1440 {
        font-size: 2.1rem;
    }

    @include narrow-desktop {
        font-size: 2.1rem;
    }

    @include ultra-narrow-desktop {
        font-size: 1.8rem;
    }

    @include tablet {
        font-size: 1.8rem;
    }

    @include narrow-tablet {
        font-size: 1.6rem;
    }
}

.p18,
.p18 p {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.45;
}

.p16,
.p16 p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.55;
}

.p14, .p14 p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
}

.p12, .p12 p {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4;

    @include mobile {
        font-size: 12px;
    }
}

.p10, .p10 p {
    font-size: 10px;
}

a {
    font-weight: 500;
}

.link-primary {
    color: $default-primary-base-color;
    font-weight: 500;

    &:hover {
        color: $default-neutral-base-color;
    }
}

.image-nofull,
.image-nofull img {
    width: auto;
    height: auto;
}

.mb-1 {
    margin-block-end: 10px !important;
}

.mb-2 {
    margin-block-end: 20px !important;
}

.mb-3 {
    margin-block-end: 30px !important;
}

.mb-4 {
    margin-block-end: 40px !important;
}

.mb-5 {
    margin-block-end: 50px !important;

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-6 {
    margin-block-end: 60px !important;

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-7 {
    margin-block-end: 70px !important;

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-8 {
    margin-block-end: 80px !important;

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-9 {
    margin-block-end: 90px !important;

    @include desktop-wide-1600 {
        margin-block-end: 70px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-10 {
    margin-block-end: 100px !important;

    @include desktop-wide-1600 {
        margin-block-end: 80px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-11 {
    margin-block-end: 110px !important;

    @include desktop-wide-1600 {
        margin-block-end: 90px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-14 {
    margin-block-end: 140px !important;

    @include desktop-wide-1600 {
        margin-block-end: 100px !important;
    }

    @include tablet {
        margin-block-end: 80px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-15 {
    margin-block-end: 150px !important;

    @include desktop-wide-1600 {
        margin-block-end: 100px !important;
    }

    @include tablet {
        margin-block-end: 90px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-16 {
    margin-block-end: 160px !important;

    @include desktop-wide-1600 {
        margin-block-end: 110px !important;
    }

    @include tablet {
        margin-block-end: 90px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.background-rosa {
    background-image: var(--background-rosa);
}

.background-azzurro {
    background-image: var(--background-azzurro);
}

.Label {
    position: absolute;
    z-index: 99;
    inset-block-start: 30px;
    inset-inline-start: 30px;
    height: 90px;
    width: 90px;
    padding: 10px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: 500;
    text-transform: uppercase;

    @include desktop-wide-1600 {
        height: 75px;
        width: 75px;
        padding: 7px;
    }

    @include desktop-wide-1440 {
        inset-block-start: 20px;
        inset-inline-start: 20px;
        height: 70px;
        width: 70px;
        padding: 5px;
    }

    @include narrow-desktop {
        inset-block-start: 20px;
        inset-inline-start: 20px;
        height: 70px;
        width: 70px;
        padding: 5px;
    }

    @include tablet {
        inset-block-start: 20px;
        inset-inline-start: 20px;
        height: 65px;
        width: 65px;
        padding: 5px;
        font-size: 1.2rem;
    }

    @include mobile {
        height: 45px;
        width: 45px;
        padding: 3px;
        font-size: 10px;
        inset-block-start: 14px;
        inset-inline-start: 14px;
    }

    &.New {
        background-color: $default-secondary-light-color;

        &.small {
            inset-block-start: 125px;
            height: auto;
            padding: 0;

            @include desktop-wide-1600 {
                inset-block-start: 110px;
            }

            @include desktop-wide-1440 {
                inset-block-start: 95px;
            }

            @include mobile {
                inset-block-start: 62px;
            }

            span {
                border-radius: 0;
                padding: 5px 0;
            }
        }

        span {
            background-color: $default-secondary-dark-color;
        }
    }

    &.Sale {
        background-color: $default-primary-light-color;
        
        span {
            background-color: $default-primary-dark-color;
            font-size: 2.1rem;

            @include mobile {
                font-size: 1.4rem;
            }
        }
    }   

    span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        border-radius: 50%;
    }
}

.Header {
    &-MinicartButtonWrapper span::before {
        content: url('images/icon-cart.svg');
        vertical-align: middle;
        margin-inline-end: 6px;
        
        @include tablet {
            transform: scale(0.75);
            display: inline-block;
            margin-inline-end: 2px;
        }
    }
}

.product-showcase {
    @include mobile {
        margin-block-end: 90px !important;
    }

    h2.section-title {
        text-align: center;

        @include mobile {
            font-size: var(--h1-font-size-mobile);
            text-align: center;
        }
    }

    h4 {
        font-weight: 400;
        text-align: center;

        @include mobile {
            & + div {
                position: absolute;
                inset-block-end: -53px;
                width: calc(100% - 18px);
                text-align: center !important;
            }       
        }

        & + div {
            text-align: end;
        }
    }

    .pagebuilder-button-link {
        font-weight: 500;
        font-size: 12px;

        @include mobile {
            @include button;

            font-size: 14px;
            height: 43px;
            min-width: 205px;
            max-width: 324px;
            text-transform: none;
            display: none;
        }

        @include desktop {
            &::after {
                content: ">";
                margin-inline-start: 4px;
                font-size: 16px;
                vertical-align: middle;
            }            
        }
    }

    .Label {
        @include mobile {
            height: 55px;
            width: 55px;
            font-size: 12px;
        }
    }
}
