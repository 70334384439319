
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #474747;
    --breadcrumbs-color: #707070;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    @include mobile {
        --breadcrumbs-background: #fff;

        display: block;
    }

    &-List {
        list-style: none;
        padding: 20px 0 0;

        @include tablet {
            padding-block-start: 10px;
        }
    }
}
