/** override sidea */

$white: #fff;
$default-primary-base-color:  #78A35E;
$default-primary-dark-color: #ADDC91;
$default-primary-light-color: #DBFEC6;
$default-primary-extralight-color: #F5FFEF;
$default-secondary-base-color: #A84EA9;
$default-secondary-dark-color: #F4A2F6;
$default-secondary-light-color: #FEE2FF;
$default-neutral-base-color: #272727;
$default-neutral-dark-color: #707070;
$default-neutral-light-color: #E3E3E3;
$default-neutral-extralight-color: #F0F0F0;
$default-neutral-thin-color: #F5F5F5;
$default-button-disabled: #C1C1C1;
$black: $default-neutral-base-color;
$font-muli: 'Poppins', sans-serif;
$font-standard-size: 62.5%;
