
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.ContattiNegozi {
    margin-inline-start: 110px;

    @include desktop-wide-1440 {
        margin-inline-start: 32px;
    }

    @include ultra-narrow-desktop {
        margin-inline-start: 32px;
    }

    @include tablet {
        margin-inline-start: 32px;
    }

    @include mobile {
        margin-inline-start: 0;
    }

    &-Negozi {
        display: grid;
        grid-template-columns: 46% 50%;

        @include mobile {
            flex-direction: column;
            display: flex;
            text-align: center;
        }
    }

    h2 {
        font-size: 3.9rem;
        font-weight: 500;
        margin-block-end: 60px;

        @include ultra-narrow-desktop {
            font-size: 3.4rem;
            margin-block-end: 40px;
        }

        @include tablet {
            font-size: 3rem;
            margin-block-end: 40px;
        }

        @include mobile {
            font-size: 2.4rem;
            font-weight: 600;
            margin-block-end: 30px;
            text-align: center;
        }
    }

    p {
        font-size: 1.6rem;
        font-weight: 300;
        margin-block-end: 5px;
    }

    svg {
        margin-inline-end: 15px;
        vertical-align: middle;

        @include mobile {
            margin-inline-end: 8px;
        }
    }

    &-SingleShop {
        margin-block-end: 50px;

        @include mobile {
            border-block-end: 1px solid#707070;
            padding-block-end: 23px;
            margin-block-end: 27px;

            &:last-child {
                border-block-end: none;
            }

            .ContattiNegozi-Number {
                margin-block-end: 10px;
            }
        }
    }

    &-Name {
        text-transform: capitalize;
        font-weight: bold !important;
    }

    &-Address {
        font-weight: bold !important;
    }

    &-Town {
        font-weight: bold !important;
        margin-block-end: 20px !important;
    }
}
