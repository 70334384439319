/** extend sidea */
/* stylelint-disable declaration-no-important */

.divider-cc {
    width: 100%;
    border-bottom: 5px solid #ADDC91;
    display: flex;
    align-self: center;

    @include mobile {
        border-bottom: 3px solid #ADDC91;

        &.mb-2 {
            margin-block-end: 10px !important;
        }
    }

    &.varFux {
        border-bottom: 5px solid #F4A2F6;
    }
}

.container-cc {
    width: 85%;
    margin: auto;

    @include desktop-wide-1600 {
        width: 87%;
    }

    @include ultra-narrow-desktop {
        width: 95%;
    }

    @include tablet {
        width: 95%;
    }

    @include mobile {
        width: calc(100% - 36px);

        &.mb-10 {
            margin-block-end: 0px !important;
        }
    }

    .p16 {
        @include desktop {
            width: 65%;
            margin: 0 auto;
        }

        @include desktop-wide-1440 {
            width: 75%;
        }

        @include narrow-desktop {
            width: 85%;
        }

        @include ultra-narrow-desktop {
            width: 90%;
        }

        @include tablet {
            width: 90%;
        }

        @include narrow-tablet {
            width: 100%;
        }

        p {
            line-height: 1.55 !important;

            @include narrow-tablet {
                font-size: 1.5rem;
            }
        }
    }

    &.list-steps {
        &.mb-7 {
            @include ultra-narrow-desktop {
                margin-block-end: 40px !important;
            }

            @include tablet {
                margin-block-end: 40px !important;
            }
        }

        .p16 {
            @include desktop {
                width: 100%;
            }
        }

        .pagebuilder-column-group {
            @include narrow-tablet {
                flex-direction: column;
                text-align: center;
                width: 100%;
            }

            .number-al {
                @include narrow-tablet {
                    width: 100% !important;
                    align-items: center;
                }

                .p16 {
                    @include narrow-tablet {
                        width: 100%;
                        max-width: 500px;
                    }

                    p {
                        @include narrow-tablet {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

.text-cc {
    width: 100%;
    max-width: 850px;
    margin: auto;

    @include mobile {
        &.p16 {
            margin-block-end: 30px;
        }
    }

    &.p16 p {
        line-height: 10px !important;

        @include narrow-tablet {
            font-size: 1.4rem;
        }
    }
}

.number-al {
    align-self: baseline !important;
}

.cc-pw {
    width: 100%;
    max-width: 300px;

    @include desktop-wide-1600 {
        width: 90% !important;
    }

    @include mobile {
        margin: 0 auto;
        margin-block-end: 40px;
    }

    &.p16 p {
        @include desktop-wide-1600 {
            font-size: 1.4rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.2rem;
        }

        @include tablet {
            font-size: 1.2rem;
        }
    }
}

