/** override sidea */

:root {
    --link-color: var(--imported_link_color, $black);
    --link-hover: var(--imported_linkHover_color, var(--primary-base-color));
    --default-hover-link-underline: 1px solid var(--link-hover);
    --link-underline: var(--imported_link_border_bottom, none);
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    border-block-end: var(--link-underline);
    color: var(--link-color);
    font-size: 14px;
    font-weight: 700;

    @include hoverable { 
        &:hover {
            color: var(--link-hover);
        }
    }
    
    &:active {
        color: var(--link-hover);
    }

    &.Link {
        &:hover,
        &:focus {
            border-block-end: var(--link-hover-underline);
            padding-block-end: 3px;
        }
    }
}
