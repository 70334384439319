
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends and override sidea */

.Footer {
    --footer-background-color: #fff;

    @include mobile {
        margin-block-end: 0;
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);

        .Prefooter {
            &-BlockWrapper {
                .ContentWrapper {
                    display: flex;
                    align-items: center;

                    @include mobile {
                        flex-direction: column;
                        row-gap: 10px;
                    }

                    .Image.Logo {
                        overflow: visible;
                        width: auto;
                        height: auto;
                        padding-inline-end: 30px;

                        @include narrow-desktop {
                            max-width: 300px;
                        }

                        @include tablet {
                            max-width: 300px;
                        }

                        @include mobile {
                            max-width: 300px;
                            padding-inline-start: 15px;
                            padding-inline-end: 15px;
                        }

                        &::after {
                            content: '';
                            border-right: 1px solid #E3E3E3;
                            width: 1px;
                            height: calc(100% - 5px);
                            position: absolute;
                            inset-inline-end: 0;

                            @include mobile {
                                display: none;
                            }
                        }

                        .Logo-Placeholder {
                            position: relative;
                        }

                        img {
                            position: relative;
                        }
                    }
            
                    .Social-box {
                        display: flex;
                        align-items: center;
                        margin-inline-start: 27px;

                        @include mobile {
                            margin-inline-start: 0;
                        }

                        div {
                            margin-inline-end: 15px;

                            img {
                                height: 18px;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    &-Columns {
        @include narrow-desktop {
            padding-block-start: 50px;
        }

        @include tablet {
            padding-block-start: 50px;
        }

        @include mobile {
            display: block;
            text-align: start;
            padding: 20px 18px;
        }
    }

    &-Column {
        @include narrow-tablet {
            width: 33% !important; /* stylelint-disable-line declaration-no-important */
            
            &:last-child {
                display: none;
            }
        }

        @include mobile {
            display: inline-block;
            width: 50%;
        }
    }

    &-ColumnItem {
        border-radius: 0;
        color: $black;
        margin-block-end: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;

        &:hover {
            color: var(--link-hover);
            text-decoration: none;
        }
    }

    &-LastColumn {
        @include narrow-tablet {
            min-width: 45%;
        }

        h3 {
            @include mobile {
                width: 50%;
                display: inline-block;
            }
        }

        .phone {
            font-size: 2.4rem;
            color: $default-primary-base-color;
            font-weight: 500;
            margin-block-end: 0;
            margin-block-start: 0;

            @include mobile {
                width: 50%;
                display: inline-block;
                font-size: 1.6rem;
                font-weight: 600;
            }
        }

        .CmsBlock-Wrapper {
            div {
                div {
                    @include mobile {
                        display: block !important;  /* stylelint-disable-line declaration-no-important */
                    }

                    .p12 {
                        @include mobile {
                            line-height: 1.5;
                        }

                        & br {
                            @include narrow-desktop {
                                display: none;
                            }

                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .link-primary,
        a {
            font-size: 12px;
            font-weight: 600;
            text-decoration: underline;
            color: $default-primary-base-color;

            &:hover {
                color: $black;
            }
        }

        .shopping-sicuro {
            &::after {
                content: var(--loghi-secure);
                display: block;
                margin-block-start: 3px;
            }
        }
    }

    &-ColumnTitle {
        color: var(--h3-color);
        font-size: var(--h3-font-size);
        font-style: var(--h3-font-style);
        line-height: var(--h3-line-height);
        text-transform: uppercase;
        font-weight: 600;

        @include narrow-desktop {
            font-size: 1.6rem;
        }

        @include tablet {
            font-size: 1.5rem;
        }

        @include mobile {
            font-size: 14px;
            margin-block-end: 5px;
        }
    }

    &::after {
        display: block;
        content: var(--background-green);
        width: 100%;
        overflow: hidden;

        @include narrow-desktop {
            max-height: 200px;
        }

        @include ultra-narrow-desktop {
            max-height: 200px;
        }

        @include tablet {
            max-height: 160px;
        }

        @include mobile {
            display: none;
        }
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
    }

    &-CopyrightContent {
        padding: 50px 32px 18px;
        justify-content: space-between;
        font-size: 12px;

        @include mobile {
            padding: 0px 18px 18px;
            flex-direction: column;
            row-gap: 7px;
            font-size: 10px;
        }

        .Footer-Copyright {
            color: $black;
            padding-inline: 0;

            @include mobile {
                font-size: 10px;
            }
        }

        a {
            font-size: 12px;
            text-decoration: underline;
            font-weight: 400;
            margin-inline-start: 5px;

            @include mobile {
                font-size: 10px;
                margin-inline-start: 10px;
            }
        }
    }

    &.CheckoutPage {
        .Newsletter-NewsletterBlockWrapper {
            display: none;
        }
    }
}

.feedaty_widget_footer {
    margin-block-start: 30px;
}
