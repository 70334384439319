
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


:root {
    --color-pagination-link-text: var(--imported_pagination_color, #CCCCCC);
    --color-pagination-link-active-text: #{$black};
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 1.6rem);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

.Pagination-ListItem {
    min-width: 23px !important; /* stylelint-disable-line declaration-no-important */
    margin-inline-end: 10px;
}

.PaginationLink {
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    display: block;
    line-height: var(--pagination-line-height);
    margin-inline-end: 0;
    padding: 7px 8px 0px;
    text-align: center;

    &_isArrow {
        padding: 7px 11px;
    }

    &:hover {
        --color-pagination-link-text: var(--color-pagination-link-active-text) !important; /* stylelint-disable-line declaration-no-important */
        
        color: var(--color-pagination-link-active-text);
        text-decoration: none;
    }

    &_isCurrent {
        --color-pagination-link-text: var(--color-pagination-link-active-text);

        border-color: var(--color-pagination-link-active-text);
        border-bottom: 1px solid var(--color-pagination-link-active-text);
    }
}
