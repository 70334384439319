
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --popup-background: #000000a3;

    @include mobile {
        --popup-content-padding: 14px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;
    --popup-max-height: 80%;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);
    display: flex;
    justify-content: center;
    align-items: center;

    &_isVisible {
        pointer-events: all;
        opacity: 1;

        @include desktop {
            width: 100vw;
            height: 100vh;
            inset-block-start: 0;
        }

        @include mobile {
            z-index: 999;
            width: 100vw;
            height: 100vh;
            inset-block-start: 0;
            overflow-y: scroll;
            align-items: flex-start;
        }

        &.Info-Popup {
            p, h2, h3, h4 {
                color: $default-primary-base-color;
            }

            h2 {
                font-size: 2rem;
                text-transform: uppercase;
            }

            p {
                font-size: 1.4rem;
            }

            .Popup {
                &-Content {
                    max-width: 750px;
                }
            }

            @include mobile {
                .popup-eco {
                    padding-inline: 25px;

                    h2 {
                        font-size: 2.4rem;
                    }

                    p {
                        font-size: 1.6rem;
                        line-height: 1.55;

                        & + p {
                            margin-block-start: 60px;
                        }
                    }
                }
            }
        }
    }

    &-Heading {
        text-decoration: none;
        font-weight: 600;
        font-size: 2rem;
        margin-block-start: 0;
    }

    &-Header {
        padding-block-start: 40px;

        @include mobile {
            display: block;
            padding-block-start: 0;
            margin-block-end: 30px;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 0;
        padding: 0 75px 40px;
        padding-block-end: 40px;
        min-width: var(--popup-min-width);
        max-width: 100%;
        overflow-x: hidden;
        max-height: var(--popup-max-height);
        overflow-y: auto;

        &::after {
            content: '';
            display: block;
            padding-block-end: var(--popup-content-padding);
        }

        @include mobile {
            border-radius: 0;
            max-height: unset;
            min-height: 100%;
            height: fit-content;
            padding: 0 30px 30px;
            z-index: 20;
        }
    }

    & &-CloseBtn {
        position: absolute;
        inset-inline-end: -44px;
        inset-block-start: 28px;
        z-index: 5;

        .CloseIcon {
            height: 20px;
            width: 20px;
        }

        @include mobile {
            inset-inline-end: 0;
            inset-block-start: 0;
            position: relative;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }

    &.RightSidebar {
        justify-content: flex-end;
        align-items: flex-start;
        transition: transform 200ms ease-in-out;
        transform: translateX(100%);
        will-change: transform;
    
        @include mobile {
            justify-content: center;
            align-items: center;
        }
        
        &_isVisible {
            transform: translateY(0%);
        }

        .Popup {
            &-Content {
                position: relative;
                background-color: #fff;
                overflow-y: scroll;
                z-index: 10;
    
                @include desktop {
                    border: 7px solid #fff;
                    width: 400px;
                    min-width: 400px;
                    height: 100%;
                    max-height: 100vh;
                    padding: 43px;
                }
    
                @include mobile {
                    width: 100%;
                    height: fit-content;
                    max-height: unset;
                    min-height: 100vh;
                    padding: 0 26px 30px;
                }
            }

            &-CloseBtn {
                inset-inline-end: auto;
                inset-inline-start: 0;
                inset-block-start: 0;
            }
        }        
    }
}

.scrollDisabled {
    margin-block-start: 0;
    width: 100%;

    @include desktop {
        position: fixed;
        overflow-y: scroll;
    }

    @include mobile {
        overflow: hidden;

        #root {
            height: 100vh;
            overflow: hidden;
        }
    }
}
