
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Override Sidea */

.FieldForm {
    &-Body {
        label {
            @include desktop {
                min-width: calc(100% - 30px);
            }
        }

        input {
            width: 100%;

            + label {
                min-width: auto;
            }
        }
    }

    &-Fields {
        margin-block-end: 12px;

        @include mobile {
            margin-block-end: 14px;
        }
    }
}
