/** extend sidea */

.heading-evidence h1,
.h2-big {
    font-size: 7.2rem;
    font-weight: 300;
    line-height: 1;
    margin-block-end: 0;
    margin-block-start: 40px;

    @include desktop-wide-1600 {
        font-size: 6.5rem;
    }

    @include desktop-wide-1440 {
        font-size: 6rem;
    }

    @include narrow-desktop {
        font-size: 5.8rem;
    }

    @include ultra-narrow-desktop {
        font-size: 5rem;
    }

    @include tablet {
        font-size: 4.8rem;
        margin-block-start: 0px;
    }

    @include narrow-tablet {
        font-size: 4rem;
    }

    @include mobile {
        font-size: 2.7rem;
        font-weight: 300;
        line-height: 1.1;
        margin-block-end: 0;
        margin-block-start: 0;
    }

    strong {
        font-weight: 600;

        @include mobile {
            font-weight: 700;
        }
    }
}

.h2-big {
    font-weight: 600;
}

.divider-n {
    width: 14%;
    border-bottom: 5px solid #272727;
    display: flex;
    margin-block-start: 30px;
    margin-block-end: 30px;

    @include mobile {
        width: 69px;
        border-block-end: 3px solid #272727;
        margin-block-start: 18px;
    }
}

.container-tn {
    width: 100%;
    max-width: 580px;
    margin: auto;

    @include desktop-wide-1440 {
        max-width: 500px;
    }

    @include narrow-desktop {
        max-width: 480px;
    }

    @include ultra-narrow-desktop {
        max-width: 400px;
    }

    @include tablet {
        max-width: 400px;
    }

    @include narrow-tablet {
        max-width: 390px;
        padding-inline-end: 20px;
    }

    @include mobile {
        max-width: 100%;
        padding-inline-start: 69px;
        padding-block-end: 20px;

        &.p24 p {
            font-size: 1.3rem;
            font-weight: var(--font-weight-p-mobile);
            line-height: 1.5;
        }
    }
}

#html-body [data-pb-style=VA3IIE3] {
    @include desktop-wide-1600 {
        justify-content: center !important; /* stylelint-disable-line */
    }
}


