
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Override Sidea */

.MenuIcon {
    @include icon-black-hover-effect;

    fill: $black;
    cursor: pointer;

    &_isActive {
        fill: var(--primary-base-color);
    }
}
