
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.SearchItem {
    &-CustomAttribute {
        color: $black;
        font-size: 12px;
        font-weight: 700;
        opacity: 1;
        text-transform: uppercase;
    }
}
