
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --content-wrapper-width: 1420px;
    --content-wrapper-large: 83vw;
    --content-wrapper-nav-width: calc(83vw - 10px);

    @include desktop-wide-1600 {
        --content-wrapper-width: 1280px;
        --content-wrapper-large: 90vw;
        --content-wrapper-nav-width: calc(90vw - 10px);
    }

    @include desktop-wide-1440 {
        --content-wrapper-width: 1180px;
    }

    @include narrow-desktop {
        --content-wrapper-width: 1150px;
        --content-wrapper-large: 90vw;
    }

    @include ultra-narrow-desktop {
        --content-wrapper-width: 95vw;
        --content-wrapper-large: calc(100vw - 80px);
        --content-wrapper-nav-width: calc(100vw - 80px);
    }

    @include tablet {
        --content-wrapper-width: 100%;
        --content-wrapper-large: calc(100vw - 64px);
        --content-wrapper-nav-width: calc(100vw - 64px);
    }
}

.Breadcrumbs {
    .ContentWrapper {
        max-width: var(--content-wrapper-large);
        padding-inline: 0;

        @include mobile {
            max-width: calc(100% - 36px);
        }
    }
}
